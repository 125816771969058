import React from 'react';
import styled from 'styled-components';
import { Section } from '../../styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import WhyImg from '../../images/brand-icon-half.png';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import 'pure-react-carousel/dist/react-carousel.es.css';

const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  @media(min-width: 768px){
    max-width: 50%;
  }
`
const WhyCont = styled.div``;
const WhyInfoCont = styled.div``;
const WhyTitle = styled(Title)`
  text-align: left;
  margin-bottom: 2rem;
`
const WhySubTitle = styled.span`
  font-weight: bold;
  line-height: 1.5rem;
  text-transform: uppercase;
`
const WhyDescription = styled.p`
  font-size: 0.9rem;
  text-align: left;
  margin-top: 0.5rem;
`
const WhyList = styled.ol`
  font-size: .8rem;
  list-style: none;
  padding: 0;
`
const WhyItem = styled.li`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Red = styled.span`
  color: ${props => props.theme.primaryColor};  
`
const WhyImage = styled.div`
  background-image: url("${WhyImg}");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: .3;
  height: 100%;
`
const SliderCustom = styled(Slider)`
  padding-bottom: 1.5rem;
  height: 100% !important;
`
const ButtonsCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`
const ButtonBackCustom = styled(ButtonBack)`
  width: 40px;
  height: 30px;
  border-radius: 50%;
  background-color: ${props => props.theme.primaryColor};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`
const ButtonNextCustom = styled(ButtonNext)`
  width: 40px;
  height: 30px;
  border-radius: 50%;
  background-color: ${props => props.theme.primaryColor};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`
export default () => (
  <Section>
    <Container>
      <WhyCont>
        <Row>
          <Col xs={12} md={2}>
            <WhyImage />
          </Col>
          <Col xs={12} md={10}>
            <WhyInfoCont>
              <WhyTitle>
              <Red>Servicios</Red>
              </WhyTitle>
            </WhyInfoCont>     
            <CarouselProvider
              naturalSlideWidth={100}
              isIntrinsicHeight={true}
              totalSlides={7}
              visibleSlides={1}
              orientation="horizontal"       
            >
              <ButtonsCont>
                <ButtonBackCustom>
                  <LeftOutlined />
                </ButtonBackCustom>
                <SliderCustom>
                  <Slide index={0}><WhyItem><WhySubTitle>Propiedades para expatriados y ejecutivos</WhySubTitle><WhyDescription>Asesoramiento en la búsqueda de viviendas y oficinas, gestión de contratos y asistencia con trámites de mudanza.</WhyDescription></WhyItem></Slide>
                  <Slide index={1}><WhyItem><WhySubTitle>Mercado de lujo y propiedades exclusivas</WhySubTitle><WhyDescription>Atención personalizada, confidencialidad y comercialización de propiedades premium con marketing especializado.</WhyDescription></WhyItem></Slide>
                  <Slide index={2}><WhyItem><WhySubTitle>Inversiones inmobiliarias</WhySubTitle><WhyDescription>Asesoría en rentabilidad, análisis de mercado y gestión de propiedades para maximizar su valor.</WhyDescription></WhyItem></Slide>
                  <Slide index={3}><WhyItem><WhySubTitle>Compra y Venta de Propiedades</WhySubTitle><WhyDescription>Evaluación de mercado, negociación y acompañamiento legal en todo el proceso de compra y venta.</WhyDescription></WhyItem></Slide>
                  <Slide index={4}><WhyItem><WhySubTitle>Alquiler de Propiedades</WhySubTitle><WhyDescription>Gestión de alquileres de corto y largo plazo, selección de inquilinos y administración de contratos.</WhyDescription></WhyItem></Slide>
                  <Slide index={5}><WhyItem><WhySubTitle>Asesoría para Inversionistas</WhySubTitle><WhyDescription>Análisis de mercado, asesoramiento en bienes raíces y estrategias de inversión inmobiliaria.</WhyDescription></WhyItem></Slide>
                  <Slide index={6}><WhyItem><WhySubTitle>Relocation & Asesoría para Expatriados</WhySubTitle><WhyDescription>Búsqueda de viviendas, asesoramiento en adaptación y apoyo en trámites de documentación.</WhyDescription></WhyItem></Slide>
                </SliderCustom>
                <ButtonNextCustom>
                  <RightOutlined />
                </ButtonNextCustom>  
              </ButtonsCont>
            </CarouselProvider>
          </Col>            
        </Row>
      </WhyCont>     
    </Container>
  </Section>
);