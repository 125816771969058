import React, { useContext } from 'react';
import context from '../../context';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Button } from '../../styled-components';
import Link from '../link';
import logoImg from '../../images/logo-light.png';
import WhyImg from '../../images/brand-icon-half.png';
import Why from '../home/why-2';

const SectionCustom = styled(Section)``;

const Logo = styled.img`
  width: 300px;
  margin-bottom: 2rem;
`;

const Image = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  object-position: center;
  margin-bottom: 2rem;
  @media(min-width: 768px){
    height: 100%;
  }
`;

const InfoCont = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Description = styled.div`
  p {
    margin-bottom: 2rem;
  }
`;

const ContactCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContactTitle = styled.h3``;

const ContactSubTitle = styled.p`
  font-size: 1.2rem;
  margin: 1.5rem 0;
`;

export default () => {
  const state = useContext(context);
  return (
    <SectionCustom>
      <Container>
        <Row>
          <Col xs={12} md={{ span: 7, order: 2 }}>
            <Image alt="historia" src={state.about.history.background} />
          </Col>
          <Col xs={12} md={{ span: 5, order: 1 }}>
            <InfoCont>
              
              <Description>
                <p>
                  <strong>Hunter Propiedades</strong> nació con una misión clara: acompañar a cada cliente en la búsqueda del lugar ideal con cercanía, empatía y compromiso. Entendemos que una propiedad no es solo un espacio, sino el escenario donde se construyen nuevas historias, se crean recuerdos y se cumplen sueños.
                </p>
                <p>
                  Con años de experiencia en el mundo inmobiliario y en relocation, sabemos que cada cliente es único. Por eso, nuestro enfoque es personalizado y humano, escuchando tus necesidades y guiándote en cada paso del proceso, ya sea para comprar, vender o alquilar.
                </p>
                <p>
                  En <strong>Hunter Propiedades</strong>, combinamos intuición y precisión para encontrar oportunidades que realmente encajen contigo. Más que una inmobiliaria, somos tu aliado en esta nueva etapa, ayudándote a tomar decisiones con confianza y tranquilidad.
                </p>
                <p>
                  <strong>Porque tu hogar no es cualquier lugar, es tu lugar. Y estamos aquí para ayudarte a encontrarlo.</strong>
                </p>
              </Description>
            </InfoCont>
          </Col>
        </Row>
        <div style={{ marginTop: "6rem" }}>
          <Why />
        </div>
        
      </Container>
    </SectionCustom>
  );
};
