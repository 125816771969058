import React, { useContext } from 'react';
import context from '../../context';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section } from '../../styled-components';

const MainCont = styled(Section)`
  //background-image: url("${props => props.src}");
  //background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
`
const Title = styled.h2`
  //width: 50%;
  color: #fff;
  span{
    color: ${props => props.theme.primaryColor};
  }
`
const VideoOverLay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;  
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
  //z-index: 100;
`
const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
`

export default ()=> {
  const state = useContext(context);
  return(
    <MainCont 
      first
      height="80vh"
      src={state.about.hero.background}
    >
      <Video
        src={require('../../videos/about.mp4')}
        autoPlay
        loop
        muted
        playsinline
        poster={require('../../videos/about-poster.webp')}
      />      
      <VideoOverLay />      
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Title>
              Sobre Hunter Propiedades
            </Title>
          </Col>
        </Row>
      </Container>
    </MainCont>
  )
}